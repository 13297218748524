import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Image from "next/image";
import Link from "next/link";

//local imports
import classes from "@/styles/generic/how-it-works.module.css";
import {
  canparImg,
  cpImg,
  easyToUseImg,
  img24x7img,
  integrationImg,
  lowShippingCostImg,
  multiCarriersImg,
  shippingLableImg,
  streamLineImg,
  trackingImg,
  upsImg,
  uspsImg,
} from "@/utils/images";
import { howItWorks } from "@/utils/content";
import { routes } from "@/utils/routes";

export default function HowItWorks() {
  return (
    <Container>
      <h2 className={classes.heading}>{howItWorks.en.title}</h2>
      <Row>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto img-fluid`}
              src={integrationImg}
              alt={integrationImg}
              height={250}
              width={240}
            />
            <h4 className={classes.cardHeading}>
              {howItWorks.en.card1Heading}
            </h4>
            <p className={classes.cardPara}>{howItWorks.en.card1Para}</p>
            <Link
              href={"/help/shipvista-help/manage-orders/connect-a-store"}
              target="_blank"
              className={classes.link}
            >
              {howItWorks.en.learnMore}
            </Link>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto img-fluid`}
              src={shippingLableImg}
              alt={shippingLableImg}
              height={250}
              width={290}
              priority
            />
            <h4 className={classes.cardHeading}>
              {howItWorks.en.card2Heading}
            </h4>
            <p className={classes.cardPara}>{howItWorks.en.card2Para}</p>
            <Link
              href={
                "/help/shipvista-help/manage-shipments/create-label-shipments"
              }
              target="_blank"
              className={classes.link}
            >
              {howItWorks.en.learnMore}
            </Link>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto img-fluid`}
              src={trackingImg}
              alt={trackingImg}
              height={250}
              width={240}
            />
            <h4 className={classes.cardHeading}>
              {howItWorks.en.card3Heading}
            </h4>
            <p className={classes.cardPara}>{howItWorks.en.card3Para}</p>
            <Link
              href={"/help/shipvista-help/manage-shipments/track-shipment"}
              target="_blank"
              className={classes.link}
            >
              {howItWorks.en.learnMore}
            </Link>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto mt-5 pt-5 img-fluid`}
              src={streamLineImg}
              alt={streamLineImg}
              height={170}
              width={240}
              style={{
                width: "auto",
                height: "auto",
              }}
            />
            <h4 className={`${classes.cardHeading} mt-4 pt-1`}>
              {howItWorks.en.card4Heading}
            </h4>
            <p className={classes.cardPara}>{howItWorks.en.card4Para}</p>
            <Link href={routes.howItWorks} className={classes.link}>
              {howItWorks.en.learnMore}
            </Link>
          </Card>
        </Col>
      </Row>
      <h2 className={`${classes.heading} mt-5`}>{howItWorks.en.title2}</h2>
      <Row className={`pt-4`}>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto`}
              src={img24x7img}
              alt={img24x7img}
              height={80}
              width={80}
            />
            <h6 className={`${classes.card2Heading} mt-4 pt-1`}>
              {howItWorks.en.card2Heading1}
            </h6>
            <p className={classes.card2Para}>{howItWorks.en.card2Para1}</p>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto`}
              src={multiCarriersImg}
              alt={multiCarriersImg}
              height={80}
              width={80}
            />
            <h6 className={`${classes.card2Heading} mt-4 pt-1`}>
              {howItWorks.en.card2Heading2}
            </h6>
            <p className={classes.card2Para}>{howItWorks.en.card2Para2}</p>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto`}
              src={lowShippingCostImg}
              alt={lowShippingCostImg}
              height={80}
              width={80}
            />
            <h6 className={`${classes.card2Heading} mt-4 pt-1`}>
              {howItWorks.en.card2Heading3}
            </h6>
            <p className={classes.card2Para}>{howItWorks.en.card2Para3}</p>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <Card className={`${classes.card} m-2 text-center border-0`}>
            <Image
              className={`m-auto`}
              src={easyToUseImg}
              alt={easyToUseImg}
              height={80}
              width={80}
            />
            <h6 className={`${classes.card2Heading} mt-4 pt-1`}>
              {howItWorks.en.card2Heading4}
            </h6>
            <p className={classes.card2Para}>{howItWorks.en.card2Para4}</p>
          </Card>
        </Col>
      </Row>
      <ul>
        <li>{howItWorks.en.l1}</li>
        <li>{howItWorks.en.l2}</li>
        <li>{howItWorks.en.l3}</li>
        <li>{howItWorks.en.l4}</li>
        <li>{howItWorks.en.l5}</li>
        <li>{howItWorks.en.l6}</li>
        <li>{howItWorks.en.l7}</li>
        <li>{howItWorks.en.l8}</li>
      </ul>
      <p className={classes.heading3}>
        <b>{howItWorks.en.title3}</b>
      </p>
      <Row className={`m-3 w-75 m-auto mb-5 ${classes.lastRow}`}>
        <Col className="text-center" xs={12} lg={3}>
          <Image src={uspsImg} alt={uspsImg} height={100} width={200} />
        </Col>
        <Col className="text-center" xs={12} lg={3}>
          <Image src={upsImg} alt={upsImg} height={100} width={200} />
        </Col>
        <Col className="text-center" xs={12} lg={3}>
          <Image src={cpImg} alt={cpImg} height={100} width={200} />
        </Col>
        <Col className="text-center" xs={12} lg={3}>
          <Image src={canparImg} alt={canparImg} height={100} width={200} />
        </Col>
      </Row>
    </Container>
  );
}
