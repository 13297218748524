import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";

//local imports
import classes from "@/styles/generic/help.module.css";
import { help } from "@/utils/content";
import Link from "next/link";
import { routes } from "@/utils/routes";
import { getArticleEntries } from "../utils/help-page-utils";
import {
  collections,
  categoryMapping,
  articleMapping,
} from "../help/help-articles-data";
import CollectionCards from "../help/collection-cards";

const allArticlesEntries = getArticleEntries();
const initialNumberOfSearch = 4;

export default function Help() {
  const [search, setSearch] = useState("");
  const [numberOfResults, setNumberOfResults] = useState(initialNumberOfSearch);

  const searchResults = !search
    ? allArticlesEntries
    : allArticlesEntries.filter((a) =>
        a.artTitle.toLowerCase().includes(search.toLowerCase())
      );

  const showMore = (amount = 0) => {
    if (amount > 0) {
      setNumberOfResults((n) => n + amount);
    } else {
      setNumberOfResults(100);
    }
  };

  return (
    <div className={`${classes.container} mb-5`}>
      <CollectionCards  />
    </div>
  );
}
