import Link from "next/link";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
  InputGroup,
  Input,
  InputGroupText,
  Card,
  CardBody,
  Button,
  Col,
  Row,
} from "reactstrap";

import {
  collections,
  categoryMapping,
  articleMapping,
} from "./help-articles-data";
import { help } from "@/utils/content";
import { getArticleEntries } from "../utils/help-page-utils";
import { routes } from "@/utils/routes";
import classes from "@/styles/generic/help.module.css";

const allArticlesEntries = getArticleEntries();
const initialNumberOfSearch = 4;

type Props = {
  showContactUs?: boolean;
};

const CollectionCards = ({ showContactUs }: Props) => {
  const content = help.en;

  const [search, setSearch] = useState("");
  const [toggleMore, setToggleMore] = useState(false);
  const [numberOfResults, setNumberOfResults] = useState(initialNumberOfSearch);

  const showMore = (amount = 0) => {
    if (amount > 0) {
      setNumberOfResults(amount);
      setToggleMore(false);
    } else {
      setToggleMore(true);
      setNumberOfResults(100);
    }
  };

  const searchResults = !search
    ? allArticlesEntries
    : allArticlesEntries.filter((a) =>
        a.artTitle.toLowerCase().includes(search.toLowerCase())
      );

  return (
    <>
      <div className="w-50 mx-auto">
        <InputGroup className={classes.inputGroup}>
          <Input
            className={`${classes.input} w-75`}
            type="search"
            placeholder={content.inputPlaceholder}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <InputGroupText>
            <FaSearch />
          </InputGroupText>
        </InputGroup>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap mx-auto">
        {search ? (
          searchResults
            .filter((_, i) => i < numberOfResults)
            .map((aE, i) => {
              const { colIndex, catKey, artKey, artTitle } = aE;
              const collection = collections[colIndex];
              const category = catKey ? categoryMapping[catKey] : null;
              const article = articleMapping[artKey];
              const partialLink = category
                ? [collection.link, category.link, article.link].join("/")
                : [collection.link, article.link].join("/");

              return (
                <div key={`${artKey}-${i}`}>
                  <Link
                    className={classes.link}
                    href={`${routes.help}/${partialLink}`}
                  >
                    <Card className={`${classes.card} m-3 mt-5 shadow-sm`}>
                      <CardBody className="d-flex justify-content-center align-items-center">
                        <h3 className={classes.cardHeading}>{artTitle}</h3>
                      </CardBody>
                    </Card>
                  </Link>
                </div>
              );
            })
        ) : (
          <>
            {collections.map((col) => {
              const { link, title, categories } = col;
              // go to the first article under the collection
              let path = routes.help;
              if (categories[0]) {
                const firstCategoryKey = categories[0];
                if (categoryMapping[firstCategoryKey]) {
                  const category = categoryMapping[firstCategoryKey];
                  if (category.articles[0]) {
                    const firstArticleKey = category.articles[0];
                    path = [
                      col.link,
                      category.link,
                      articleMapping[firstArticleKey].link,
                    ].join("/");
                  }
                }
              }
              return (
                <div key={link}>
                  <Link
                    className={classes.link}
                    href={`${routes.help}/${path}`}
                  >
                    <Card className={`${classes.card} m-3 mt-5 shadow-sm`}>
                      <CardBody className="d-flex justify-content-center align-items-center">
                        <h3 className={classes.cardHeading}>{title}</h3>
                      </CardBody>
                    </Card>
                  </Link>
                </div>
              );
            })}
          </>
        )}
      </div>
      {showContactUs && (
        <Row className="d-flex justify-content-center">
          <Col sm="2">
            <Link href={routes.contact} className={classes.link}>
              <Card className={`${classes.card} m-3 mt-5 shadow-sm`}>
                <CardBody className="d-flex justify-content-center align-items-center">
                  <h3 className={classes.cardHeading}>Contact Us</h3>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
      )}
      {search &&
        (searchResults.length > numberOfResults ||
          numberOfResults > initialNumberOfSearch) && (
          <Button
            className={classes.seeMoreBtn}
            onClick={() => (toggleMore ? showMore(4) : showMore())}
          >
            {toggleMore ? "Show Less" : "Show More"} Results
          </Button>
        )}
    </>
  );
};

export default CollectionCards;
